import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Team Box
import TeamBox from "./team-box";

class AboutUs extends Component {
  state = {
    members: [
      {
        id: 1,
        name: "Name Johnson",
        image: "assets/images/team/img-1.jpg",
        post: "CEO",
      },
      {
        id: 2,
        name: "Name 1",
        image: "assets/images/team/img-2.jpg",
        post: "DESIGNER",
      },
      {
        id: 3,
        name: "Name 2",
        image: "assets/images/team/img-3.jpg",
        post: "DEVELOPER",
      },
      {
        id: 4,
        name: "Name 3",
        image: "assets/images/team/img-4.jpg",
        post: "MANAGER",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section" id="about">
          <Container>
            <Row>
              <Col lg={{ size: 8, offset: 2 }}>
                <div className="about-title mx-auto text-center">
                  <h2 className="font-weight-light">
                    About Inbixus{" "}
                  </h2>
                  <p className="text-muted pt-4">
                  Inbixus is a solid and constantly growing IT consultancy & staffing agency. Based in the city of Guadalajara, Jalisco and a connection office in the city of Austin, Texas; one of the biggest state-of-the-art IT Hubs in Mexico and the US, respectively. 
                  </p>
                  <p className="text-muted pt-4">
                  Leadership has around 15+ years of experience in the IT Industry with a young and fresh spirit; and always keeping an open mind for new ideas or concepts to flow.
                  </p>
                  <p className="text-muted pt-4">
                  We cannot wait to tell you all about it, please reach out to us at <a href="emailto: info@inbixus.com" target="_blank" rel="noreferrer">info@inbixus.com.</a>  We'd love to hearing back from you!
                  </p>
                </div>
              </Col>
            </Row>
            
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUs;
