import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Importing Modal
import ModalSection from "../../components/common/ModalSection";

class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.callModal.bind(this);
  }

  callModal = () => {
    this.refs.child.openModal();
  };

  render() {
    return (
      <React.Fragment>
        <section className="section bg-home home-half" id="home" data-image-src="images/bg-home.jpg"> 
          <div className="bg-overlay"></div>
          <Container>
            <Row>
              <Col
                lg={{ size: 8, offset: 2 }}
                className="text-white text-center"
              >
                <h4 className="home-small-title">Welcome to Inbixus</h4>
                <h1 className="home-title">
                  Your IT solutions and talent provider partner
                </h1>
                <p className="pt-3 home-desc mx-auto">
                Bring together top talent and innovative solutions to help businesses thrive in the ever-evolving technology landscape. With a team of experts specializing in a wide range of IT disciplines, we are committed to providing customized and strategic support to meet the unique needs of each client. Let our experience and dedication drive your success in the digital world.
                </p>
                
              </Col>
              
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
