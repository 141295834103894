import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";

class Services extends Component {
  state = {
    services: [
      {
        title: "Staff Augmentation & Extended Teams",
        icon: "pe-7s-users",
        description:
          "Extend your Agile team with talented resources - Scale up/down your remote team - Remote, Hybrid, In Office, Full time, Part time - Fluent English/Spanish communication - PST, MST, CST, EST Supported time zones",
      },
      {
        title: "IT Consultancy & Managed Services",
        icon: "pe-7s-cloud",
        description:
          "Frontend, Backend, Mobile Development, Quality Assurance Automated & Manual, Security DevOps, Scrum with Agile Methodologies-Iterations, Cloud Management, Product Owner, Technical Leads, Delivery Management",
      },
      {
        title: "Mobile & Web Product Design/Development",
        icon: "pe-7s-phone",
        description:
          "IT Product development & Implemantation",
      },
      
      {
        title: "Cyber & Information Security",
        icon: "pe-7s-key",
        description:
          "Cybersecurity, PenTesting, Vulnarabilities reports, OWASP Top 10 alignments",
      },
      {
        title: "24/7 IT Product support",
        icon: "pe-7s-clock",
        description:
          "Daily Operations 24x7 Support",
      },
      {
        title: "Research & Development Lab",
        icon: "pe-7s-news-paper",
        description:
          "Technology, Tools and concepts research",
      },
      {
        title: "Trainings",
        icon: "pe-7s-study",
        description:
          "Remote/Onsite team education",
      },
      
      
      
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="services">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Our Services"
              description=""
            />

            <Row className="mt-5">
              {/* render service box */}
              {this.state.services.map((service, key) => (
                <ServiceBox
                  key={key}
                  title={service.title}
                  icon={service.icon}
                  description={service.description}
                />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Services;
