import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

class Features extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section" id="features">
          <Container>
            <Row className="align-items-center">
              <Col lg="5" className="order-2 order-lg-1">
                <div className="features-box mt-5 mt-lg-0">
                  <h3>
                    Elevate Your Business with Expert IT Consulting and Staffing Solutions
                  </h3>
                  <p className="text-muted web-desc">
                  Staffing and IT consultancy services provide companies with comprehensive human resources and technology solutions. The staffing side of the business assists clients with recruiting, hiring, and managing employees. Consultants work closely with hiring managers to understand their staffing needs and culture. They source qualified candidates, screen them through interviews and assessments, and present the best options. Once a candidate is hired, the staffing firm handles all HR administration like payroll, benefits, and compliance. This allows the client to focus on their core business while accessing talent.

Meanwhile, the IT consultancy arm advises clients on optimizing their technology strategy and systems. Consultants evaluate the client’s existing IT infrastructure and needs. They provide advice on solutions like cloud computing, cybersecurity, software integration, and emerging technologies. Consultants create roadmaps to guide technical improvements and migrations. They can project manage complex IT initiatives from vendor selection to system implementation and change management. Ongoing support is provided through managed services. Together, the staffing and consultancy services provide complete workforce and IT solutions tailored to each client’s unique needs. This enables organizations to access specialized expertise and scale capabilities on-demand.
                  </p>
                  <ul className="text-muted list-unstyled mt-4 features-item-list">
                    <li className="">We put a lot of effort in design.</li>
                    <li className="">
                      The most important ingredient of successful website.
                    </li>
                    <li className="">Submit Your Orgnization.</li>
                  </ul>
                  <Link
                    to="#"
                    className="btn btn-primary mt-4 waves-effect waves-light"
                  >
                    Learn More <i className="mdi mdi-arrow-right"></i>
                  </Link>
                </div>
              </Col>
              <Col lg={{ size: 7, order: 2 }} xs={{ order: 1 }}>
                <div className="features-img mx-auto me-lg-0">
                  <img
                    src="assets/images/growth-analytics.svg"
                    alt="macbook"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
